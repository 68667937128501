body {
    overflow-x: hidden;
}

iframe { 
    width: 100%;
    aspect-ratio: 16 / 9;
  }

@font-face {
    font-family:montserrat;
    src: url('../public/Montserrat-Medium.ttf');
    /* font-family:gilroy_regular; */
    /* src: url('../public/Gilroy-Regular.ttf'); */
}

@font-face {
    font-family: archivo_black;
    src: url('../public/ArchivoBlack-Regular.ttf');
}

@font-face {
    font-family:montserrat_bold;
    src: url('../public/Montserrat-Bold.ttf');
    /* font-family:gilroy_bold; */
    /* src: url('../public/Gilroy-Bold.ttf'); */
}


@font-face {
    font-family:font_thin_large;
    src: url('../public/Kanit-Regular.ttf');
}

.font_thin{
    /* font-family:font_thin_large !important; */
    font-family:montserrat !important;
}

/* .ant-progress-steps-item{
    width: 45px !important;
} */

/* .ant-steps-item-tail {
    width: 50px !important;
} */

/* .ant-steps.ant-steps-label-vertical .ant-steps-item-tail { */
    /* width: 50px !important; */
    /* margin-inline-start: 36px !important; */
/* } */

.font_normal{
    font-family:montserrat !important;
    /* font-family:gilroy_regular !important; */
}

.font_bold{
    font-family:montserrat_bold;

    /* font-family:gilroy_bold !important; */
}

.font_thicker{
    font-family:archivo_black !important;
}

.ant-steps-item-content{
    font-size: 15px !important;
    font-family:gilroy_bold !important;
}

.ant-steps-item-title{
    font-size: 12px !important;

}

#font_heading{
    font-family:montserrat_bold !important;
    /* font-family:gilroy_bold !important; */
    font-size:20px;
    margin: 15px 0px;
}

#font_sub_heading{
    font-family:montserrat_bold !important;
    /* font-family:gilroy_bold !important; */
    font-size:14px;
    margin: 15px 0px;
}

#font_description{
    font-family:montserrat !important;
    /* font-family:gilroy_regular !important; */
    font-size:14px;
    margin: 10px 0px;
}

#high_lighter:hover{
    color: #e67816;
    font-weight: 800;
}


.nav-header {
    cursor: pointer;
    position: relative;
}
.nav-header:hover + .menu-sub {
    display: block;
}

.menu-sub {
    position: absolute;
    z-index: 10;
    display: none;
    top: 30px;
    width: 150px;
    padding: 0px;
    text-align: left;
    transition-delay: 300ms;
}

.menu-sub:hover {
    display: block;
}

.ant-steps-item-icon {
    background-color:#027d9b !important;
    border:1px solid #027d9b !important;
}

.ant-steps-item-icon:hover {
    color: black !important;
}

.ant-steps-item-container {
    width: 80px !important;
}

.ant-steps-icon{
    color: white !important;
}

.ant-steps-item-title{
    color: black !important;
    font-size: 10px !important;
    width: 90px !important; 
    line-height: 13px !important;
    font-family:montserrat !important;
}

.ant-steps-item-tail{
    color: gray !important;
}

.ant-steps-item-tail::after{
    color: gray !important;
}



/* css for application  */