@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-header {
    cursor: pointer;
    position: relative;
}
.nav-header:hover + .menu-sub {
    display: block;
}

.menu-sub {
    position: absolute;
    z-index: 152999;
    display: none;
    top: 30px;
    width: 150px;
    padding: 0px;
    text-align: left;
    transition-delay: 300ms;
}

.menu-sub:hover {
    display: block;
}
